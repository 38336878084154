import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import { Button, Typography } from "@mui/material";

const Auth : React.FC = () => {

    const { accounts, instance } = useMsal();
    const [profileName, setProfileName] = useState("User");
    
    useEffect(() => {
        if (accounts[0] && accounts[0]?.name) {
            setProfileName(accounts[0]?.name);
        }
    }, [accounts]);

    return (<Typography>{profileName} <Button style={{'color': '#FFF'}} onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })}><LogoutIcon /></Button></Typography>);
}

export default Auth;
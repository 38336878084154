import { EditorState, Modifier } from 'draft-js';
import { useEffect, useState } from 'react';

type Props = {
    onChange: (state: EditorState) =>  void;
    editorState: EditorState;
    options: string[];
}

const Placeholder = (props : Props) => {

    const [expanded, setExpanded] = useState(false);

    const addPlaceholder = (placeholder: string): void => {
        const { editorState, onChange } = props;
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            `{{${placeholder}}}`,
            editorState.getCurrentInlineStyle(),
        );
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    }

    useEffect(() => {
        setExpanded(false);
    }, []);

    return (<div onClick={() => setExpanded(!expanded)} className="rdw-block-wrapper" aria-label="rdw-block-control" role="button" tabIndex={0}>
    <div className="rdw-dropdown-wrapper rdw-block-dropdown" aria-label="rdw-dropdown" style={{width: 290}}>
      <div className="rdw-dropdown-selectedtext">
        <span>Add Placeholder</span>
        <div className={`rdw-dropdown-caretto${expanded ? 'close' : 'open'}`} />
      </div>
      <ul className={`rdw-dropdown-optionwrapper ${expanded ? '' : 'placeholder-ul'}`}>
        {props.options.map(item => (
          <li onClick={() => addPlaceholder(item)} key={item} className="rdw-dropdownoption-default placeholder-li">
            {item}
          </li>
        ))}
      </ul>
    </div>
  </div>);
}

export default Placeholder;
import './App.css';
import { Box, CssBaseline, Toolbar, Typography, AppBar, Grid } from '@mui/material';
import { useLocation, Route, Routes, useNavigate } from "react-router-dom";

import Nav from './components/common/Nav';
import AppRoutes from "./AppRoutes";
import { AppRoute } from './models/common/AppRoute';

import { useState, useEffect } from 'react'
import { IPublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { CustomNavigationClient } from './utils/NavigationClient';
import { loginRequest } from "./authConfig";
import Auth from './components/common/Auth';
import TemporaryBanner from './components/common/TemporaryBanner';

const drawerWidth = 240;


interface AppProps {
  pca: IPublicClientApplication
};

function App({ pca }: AppProps) {

  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  const authRequest = {
    ...loginRequest
  };

  const location = useLocation();
  let locName = "Dashboard";
  const [locationName, setLocationName] = useState(locName);
  const [activeData, setActiveData] = useState<string | null>('');

  const activeRoute = (routeName: string) => {
    routeName = routeName.replace("/:id","");
    return location.pathname.indexOf(routeName) > -1;
  };

  const createRoutes = (routes: AppRoute[]) => {
    return routes.map((route : AppRoute) => {
      
      if(activeRoute(route.path)){
        locName = route.name;
      }

        return (
          <Route 
              path={route.path}
              Component={route.component}
              key={route.path}
              
          />
      )
    })
  }

  useEffect(() => {
    setLocationName(locName);

    const activeData = localStorage.getItem('activeData');

    setActiveData(activeData);
  }, [locName, activeData]);

  const ErrorComponent = (error: any) => {
    console.log(error);
    return (<>Sorry, there was an error...</>)
  }

  return (
    <div className="App">
      <MsalProvider instance={pca}>
        <MsalAuthenticationTemplate 
                interactionType={InteractionType.Redirect} 
                authenticationRequest={authRequest} 
                errorComponent={ErrorComponent} 
            >
          <TemporaryBanner name={"Question Editor"}/>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline/>
            <Nav drawerWidth={drawerWidth}  />
            <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, marginTop: '78px'}}>
              <Toolbar>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Typography variant="h6" noWrap component="div">{locationName} - ({activeData})</Typography>
                  </Grid>
                  <Grid item xs={3} display={'flex'} justifyContent="flex-end">
                    <Auth />
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <main className='main-content'>
              <Routes>
                {createRoutes(AppRoutes)}
              </Routes>
            </main>
          </Box>
          </MsalAuthenticationTemplate>
      </MsalProvider>
    </div>
  );
}

export default App;

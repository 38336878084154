import { Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/ContentCopy';
import axios from 'axios';
import { useEndpoints } from '../utils/EndpointContext';

type Props = {}

const DashboardPage = (props: Props) => {

    const endPoints = useEndpoints();
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);
    const [savedData, setSavedData] = useState<string[] | null>();
    const [activeData, setActiveData] = useState<string>();

    const handleSetActive = (name : string) => {
        localStorage.setItem('activeData', name);
        window.location.reload();
    }

    const handleDownload = async (name : string) => {
        axios.get(endPoints.questionEditorRead + "&BlobName=" + name).then((response) => {
            const blob = new Blob([JSON.stringify(response.data, null, 2)], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = name;
            a.click();
            URL.revokeObjectURL(url);
        });
    }

    const handleCopy = async (name : string) => {
        axios.get(endPoints.questionEditorRead + "&BlobName=" + name).then((response) => {
            axios.post(endPoints.questionEditorCreate!, response.data).then(() => {
                window.location.reload();
            });
        });
    }

    const renderSavedData = () => {
        return savedData?.map((item, index) => {
            return <Paper key={index} style={{marginBottom: '1em'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>{item}</Grid>
                        <Grid item xs={4}>
                            <Stack spacing={1} className="tools" direction="row" justifyContent={"end"}>
                                {activeData !== item ? <Button variant="contained" color='primary' onClick={() => handleSetActive(item)}>Set Active <EditIcon /></Button> : <></>}
                                <Button variant="contained" color='secondary' onClick={() => handleCopy(item)}>Copy <CopyIcon /></Button>
                                <Button variant="contained" color='secondary' onClick={() => handleDownload(item)}>Export <DownloadIcon /></Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
        });
    };

    useEffect(() => {

        if(hasLoaded){ return; }

        const activeData = localStorage.getItem('activeData');
        setActiveData(activeData ? activeData : '');

        axios.get(endPoints.questionEditorList!).then((response) => {
            const data = response.data;

            data.sort((a : string, b : string) => {
                const formatDate = (str: string) => {
                  const parts = str.split('/');
                  return parts[0] + parts[1].split('.')[0];
                };
              
                if (formatDate(a) > formatDate(b)) {
                    return -1;
                  } else if (formatDate(a) < formatDate(b)) {
                    return 1;
                  } else {
                    return 0;
                  }
              });

            setSavedData(response.data);
        });

        setHasLoaded(true);

    }, []);


    return(
        <Grid container spacing={2} mb={2}>
            <Grid item xs={12}>
                <Stack>
                    <Typography>Saved Versions</Typography>
                    <Divider style={{marginBottom: "1em", marginTop: "1em" }} />
                    <Stack>
                        {savedData ? renderSavedData() : <>No saved data</> }
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default DashboardPage;
import { Alert, Box, Grid, Paper, Snackbar, Stack, Typography } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { DataModel } from '../models/DataModel';
import { useNavigate } from 'react-router-dom';
import { Question } from '../models/QuestionModel';
import { useEndpoints } from '../utils/EndpointContext';

type Props = {}

const QuestionsPage: React.FC = (props: Props) => {
    const endPoints = useEndpoints();
    const navigate = useNavigate();

    const itemsEndRef = useRef<null | HTMLDivElement>(null);
    const [itemAdded, setItemAdded] = useState(false);
    const [baseData, setBaseData] = useState<DataModel>();
    const [data, setData] = useState<Question[]>([]);
    const [openAlert, setOpenAlert] = useState<boolean>(false);

    useEffect(() => {
        const activeData = localStorage.getItem('activeData');

        axios.get(`${endPoints.questionEditorRead}&blobName=${activeData}`)
        .then(response => {
            setBaseData(response.data);
            setData(response.data.Questions);
        });
    }, []);

    useEffect(() => {
        if (itemAdded && itemsEndRef.current) {
          itemsEndRef.current.scrollIntoView({ behavior: 'smooth' });
          setItemAdded(false);
        }

        if(itemAdded){
            handleSave();
        }
      }, [itemAdded]);

    const handleSave = () => {
        const activeData = localStorage.getItem('activeData');
        baseData!.Questions = data;
        axios.post(`${endPoints.questionEditorUpdate}&blobName=${activeData}`, baseData);
    };

    const handleEditQuestion = (idx : number) => {
        navigate(`/questions/${idx}`);
    };

    const handleEditAnswers = (idx : string) => {
        navigate(`/questions/${idx}/answers`);
    };

    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const handleAdd = () => {

        const lastVersion = data[data.length - 1].Id;
        const nextVersion = incrementVersion(lastVersion);

        setData([...data, { Id: nextVersion, Section: '', Question: 'Click edit to update' }]);
        setItemAdded(true);
    };

    const handleRemove = (index: number) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
        setItemAdded(true);
    };

    const incrementVersion = (version: string): string => {
        const parts = version.split('.').map(Number);
        
        if (parts.length === 3) {
            parts[2] += 1;
        } else if (parts.length === 2) {
            parts.push(1);
        }

        if (parts[2] && parts[2] > 9) {
            parts[2] = 0;
            parts[1] += 1;
        }
        
        if (parts[1] && parts[1] > 9) {
            parts[1] = 0;
            parts[0] += 1;
        }
        
        return parts.join('.');
    }

    return(
        <div>
            {data ?
            <>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginBottom: '1em'}}>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color="secondary" onClick={handleAdd}>Create Question <CreateIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Stack spacing={2}>
                    {data.map((item, index) => (
                        <Paper key={index}>
                            <Grid container spacing={2}>
                                <Grid item xs={1}>
                                    <Typography>{item.Id}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography>{item.Section}</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography>{item.Question}</Typography>
                                </Grid>
                                <Grid item xs={3} className='actions-bar tools' display="flex" justifyContent="flex-end">
                                    <Button color='primary' onClick={() => handleEditQuestion(index)}>  Question <EditIcon /></Button>
                                    <Button color='primary' onClick={() => handleEditAnswers(item.Id)}> Answers <EditIcon /></Button>
                                    <Button color='error' onClick={() => handleRemove(index)}> <DeleteIcon /></Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    ))}
                </Stack>
                <div ref={itemsEndRef}></div>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginTop: '1em'}}>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color="secondary" onClick={handleAdd}>Create Question <CreateIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                        Question added successfully.
                    </Alert>
                </Snackbar>
            </>
            : <>Loading Questions</>}
        </div>
    );
}

export default QuestionsPage;
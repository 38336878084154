export enum AnswerType {
  input,
  bool,
  list,
  int,
  float,
  text
}

export interface QuestionAndAnswerValues {
  Key: string,
  Values?: string[] | string,
  Type: AnswerType,
  Options?: string[],
  DecimalPlaces?: number,
  Nullable: boolean
}

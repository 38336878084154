import { Alert, Box, Breadcrumbs, Grid, Link, Paper, Snackbar, Stack, Typography } from '@mui/material';
import { useState, useEffect, useRef, useCallback } from 'react';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import axios from 'axios';
import TextInput from '../components/forms/TextInput';
import { DataModel } from '../models/DataModel';
import { useNavigate, useParams } from 'react-router-dom';
import { Question } from '../models/QuestionModel';
import { useEndpoints } from '../utils/EndpointContext';

type Props = {}
type QuestionParams = {
    qid: string;
};

const QuestionPage: React.FC = (props: Props) => {
    const endPoints = useEndpoints();
    const navigate = useNavigate();

    let { qid } = useParams<QuestionParams>();
    const questionIndex = (qid) ? parseInt(qid!) : 0;

    const defaultBaseData: DataModel = {
        Informatives: [],
        Companies: [],
        Answers: [],
        DateCreated: '',
        CreatedBy: '',
        Questions: []
    };

    const defaultQuestion: Question = {
        Id: '',
        Section: '',
        Question: ''
    }

    const itemsEndRef = useRef<null | HTMLDivElement>(null);
    const [itemAdded, setItemAdded] = useState(false);
    const [baseData, setBaseData] = useState<DataModel>(defaultBaseData);
    const [data, setData] = useState<Question>(defaultQuestion);
    const [openAlert, setOpenAlert] = useState<boolean>(false);

    useEffect(() => {
        const activeData = localStorage.getItem('activeData');

        axios.get(`${endPoints.questionEditorRead}&blobName=${activeData}`)
        .then(response => {
            setBaseData(response.data);
            setData(response.data.Questions[questionIndex]);
        });
    }, []);

    useEffect(() => {
        if (itemAdded && itemsEndRef.current) {
          itemsEndRef.current.scrollIntoView({ behavior: 'smooth' });
          setItemAdded(false);
        }
      }, [itemAdded]);

    const handleIdChange = useCallback((value: string) => {
        const newData = {...data};
        newData!.Id = value;
        setData(newData);
    }, [data]);

    const handleQuestionChange = useCallback((value: string) => {
        const newData = {...data};
        newData!.Question = value;
        setData(newData);
    }, [data]);

    const handleSectionChange = useCallback((value: string) => {
        const newData = {...data};
        newData!.Section = value;
        setData(newData);
    }, [data]);

    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const handleSave = () => {
        const activeData = localStorage.getItem('activeData');
        baseData.Questions[questionIndex] = data;
        axios.post(`${endPoints.questionEditorUpdate}&blobName=${activeData}`, baseData);
        setOpenAlert(true);
    };

    return(
        <div>
            {data ?
            <>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginBottom: '1em'}}>
                    <Grid item xs={6}>
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                            <Link underline="hover" onClick={() => { navigate(`/questions`) }}>Questions</Link>
                            <Typography color="text.primary">Question {data.Id}</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color='primary' onClick={handleSave}>Save Question<SaveIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Stack spacing={2}>
                    <Typography>Question</Typography>
                    <Paper>
                        <Box sx={{ m: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={1}>
                                    <TextInput fieldName={'id'} label={'ID'} value={data.Id} onChange={(value) => handleIdChange(value)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <TextInput fieldName={'section'} label={'Section'} value={data.Section} onChange={(value) => handleSectionChange(value)} />
                                </Grid>
                                <Grid item xs={10}>
                                    <TextInput fieldName={'question'} label={'Question'} value={data.Question} onChange={(value) => handleQuestionChange(value)} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Stack>
                <div ref={itemsEndRef}></div>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginTop: '1em'}}>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color='primary' onClick={handleSave}>Save Question <SaveIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                        Question updated successfully.
                    </Alert>
                </Snackbar>
            </>
            : <>Loading Question</>}
        </div>
    );
}

export default QuestionPage;
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { Endpoints, loadEndpoints } from '../data/Endpoints';

const endPointsContext = createContext<Endpoints | null>(null);

type ConfigProviderProps = {
    children: ReactNode;
  };
  
export const EndpointsProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const [config, setConfig] = useState<Endpoints | null>(null);

  useEffect(() => {
    loadEndpoints().then(setConfig).catch(console.error);
  }, []);

  if (!config) {
    return <div>Loading configuration...</div>;
  }

  return (
    <endPointsContext.Provider value={config}>
      {children}
    </endPointsContext.Provider>
  );
};

export const useEndpoints = () => {
  const context = useContext(endPointsContext);
  if (context === null) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};
